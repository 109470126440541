<template>
  <div class="protocol-box">
    <div>
      <p>鸿联云平台服务协议</p>
      <div>
        <p>
          欢迎您与鸿联云平台经营者（详见定义条款）共同签署本《鸿联云平台服务协议》（下称“本协议”）并使用鸿联云平台服务！
        </p>
        <p>
          本协议为《鸿联云服务协议》修订版本，自本协议发布之日起，鸿联云平台各处所称“鸿联云服务协议”均指本协议。
        </p>
        <p>
          各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
        </p>
        <p>
          【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向鸿联云平台客服咨询。
        </p>
        <p>
          【注册动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与鸿联云达成一致，成为鸿联云平台“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
        </p>
        <strong>一、 定义</strong>
        <p>
          鸿联云平台经营者（或简称“鸿联云”）：是指“北京掌中无限信息技术有限公司”。
        </p>
        <p>鸿联云平台：是指鸿联云网站（域名为gitok.com）及客户端。</p>
        <p>
          鸿联云平台服务：鸿联云经营者基于互联网，以包含鸿联云平台网站（gitok.com）、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。
        </p>
        <p>
          鸿联云平台规则：包括鸿联云经营者在鸿联云平台规则频道内已经发布及后续发布的全部规则、解读、公告等内容以及各平台在帮派、论坛、帮助中心内发布的各类规则、实施细则、产品流程说明、公告等。
        </p>
        <p>
          同一用户：使用同一身份认证信息或经鸿联云排查认定多个鸿联云账户的实际控制人为同一人的，均视为同一用户。
        </p>
        <strong>二、 协议范围</strong>
        <p><em>2.1 签约主体</em></p>
        <p>
          <em>【平等主体】</em
          >本协议由您与鸿联云平台经营者共同缔结，本协议对您与鸿联云平台经营者均具有同等法律效力。
        </p>
        <p>
          <em>【主体信息】</em
          >鸿联云平台经营者是指经营鸿联云平台的法律主体北京掌中无限信息技术有限公司。
        </p>
        <p>
          <em
            >您同意：<span
              >鸿联云平台经营者可能根据鸿联云平台的业务调整而发生变更，变更后的鸿联云平台经营者与您共同履行本协议并向您提供服务，鸿联云平台经营者的变更不会影响您本协议项下的权益。鸿联云平台经营者还有可能因为提供新的鸿联云平台服务而新增，如您使用新增的鸿联云平台服务的，视为您同意新增的鸿联云平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方</span
            ></em
          >
        </p>
        <strong>三、 账户注册与使用</strong>
        <p><em>3.1 用户资格</em></p>
        <p>
          在您开始注册程序使用鸿联云平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。<em
            ><span
              >若您不具备前述与您行为相适应的民事行为能力，请立即停止注册行为，否则您及您的监护人应依照法律规定承担因此而导致的一切后果。</span
            ></em
          >
        </p>
        <p>
          此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用鸿联云平台服务。
        </p>
        <p><em>3.2 账户说明</em></p>
        <p>
          <em>【账户获得】</em>
          当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得鸿联云平台账户并成为鸿联云平台用户。
        </p>
        <p>
          <em>【账户获得】</em>
          您有权使用您设置或确认的鸿联云会员名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录鸿联云平台。
        </p>
        <p>
          由于您的鸿联云账户关联您的个人信息及鸿联云平台商业信息，您的鸿联云账户仅限您本人使用。未经鸿联云同意，您直接或间接授权第三方使用您鸿联云账户或获取您账户项下信息的行为无效。如鸿联云判断您鸿联云账户的使用可能危及您的账户安全及/或鸿联云平台信息安全的，鸿联云可拒绝提供相应服务或终止本协议。
        </p>
        <p>
          <em>【账户转让】</em>
          由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经鸿联云同意，并符合鸿联云平台规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。
          <em
            ><span
              >除此外，您的账户不得以任何方式转让，否则鸿联云有权追究您的违约责任，且由此产生的一切责任均由您承担。</span
            ></em
          >
        </p>
        <p>
          <em>【实名认证】</em>
          作为鸿联云平台经营者，为使您更好地使用鸿联云平台的各项服务，保障您的账户安全，鸿联云可要求您按第三方支付公司要求及我国法律规定完成实名认证。
        </p>
        <p><em>3.3 注册信息管理</em></p>
        <p>
          <em>【信息真实】</em>
          在使用鸿联云平台服务时，您应当按鸿联云平台页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等），以便鸿联云或其他用户与您联系。
          <em
            ><span
              >您了解并同意，您有义务保持您提供信息的真实性及有效性。</span
            ></em
          >
        </p>
        <p>
          <em
            >【用户名的合法性】<span>您设置的鸿联云用户名不得违反国家法律法规及鸿联云平台规则关于会员名的管理规定，否则鸿联云可回收您的鸿联云用户名。</span>鸿联云会员名的回收不影响您以邮箱、手机号码登录鸿联云平台并使用鸿联云平台服务。</em
          >
        </p>
        <p><em>3.3.2 更新维护</em></p>
        <p>
          您应当及时更新您提供的信息，在法律有明确规定要求鸿联云作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，鸿联云将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。
        </p>
        <p>
          <em
            ><span
              >如鸿联云按您最后一次提供的信息与您联系未果、您未按鸿联云的要求及时提供信息、您提供的信息存在明显不实或行政、司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及鸿联云造成的全部损失与不利后果。鸿联云可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部鸿联云平台服务，鸿联云对此不承担责任。</span
            ></em
          >
        </p>
        <p><em>3.4 账户安全规范</em></p>
        <p>
          <em>【账户安全保管义务】</em
          >您的账户为您自行设置并由您保管，鸿联云任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开鸿联云平台。
        </p>
        <p>
          <em
            ><span
              >账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，鸿联云并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</span
            ></em
          >
        </p>
        <p>
          <em>【账户行为责任自负】</em
          ><span
            >您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。</span
          >
        </p>
        <p>
          <em>【日常维护须知】</em
          >如发现任何未经授权使用您账户登录鸿联云平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知鸿联云，并授权鸿联云将该信息同步给第三方支付公司。<em
            ><span
              >您理解鸿联云对您的任何请求采取行动均需要合理时间，且鸿联云应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除鸿联云存在法定过错外，鸿联云不承担责任。</span
            ></em
          >
        </p>
        <strong>四、 鸿联云平台服务及规范</strong>
        <p>
          <em>【服务概况】</em
          >您有权在鸿联云平台上享受店铺管理、商品及/或服务的销售与推广、商品及/或服务的购买与评价、交易争议处理等服务。鸿联云提供的服务内容众多，具体您可登录鸿联云平台浏览。
        </p>
        <p><em>4.1 店铺管理</em></p>
        <p>
          <em>【店铺创建】</em
          >通过在鸿联云平台创建店铺，您可发布相关服务信息并与其他用户达成交易。
        </p>
        <p>
          <em>【店铺转让】</em
          >由于店铺与账户的不可分性，店铺转让实质为店铺经营者账户的转让，店铺转让的相关要求与限制请适用本协议3.2条账户转让条款。
        </p>
        <p>
          <em>【店铺关停】</em>如您通过下架全部商品短暂关停您的店铺，<em
            ><span
              >您应当对您店铺关停前已达成的交易继续承担开发,设计及质保、维权投诉处理等交易保障责任。</span
            ></em
          >
        </p>
        <p><em>4.2商品及/或服务的销售与推广</em></p>
        <p>
          <em>【商品及/或服务信息发布】</em
          >通过鸿联云提供的服务，您有权通过文字、图片、视频、音频等形式在鸿联云平台上发布商品/或服务信息、招揽和物色交易对象、达成交易。
        </p>
        <p>
          <em>【禁止销售范围】</em
          >您应当确保您对您在鸿联云平台上发布的商品及/或服务享有相应的权利，<em
            ><span
              >您不得在鸿联云平台上销售以下商品及/或提供以下服务：</span
            ></em
          >
        </p>
        <p>
          <em><span>（一）国家禁止或限制的；</span></em>
        </p>
        <p>
          <em><span>（二）侵犯他人知识产权或其它合法权益的；</span></em>
        </p>
        <p>
          <em
            ><span
              >（三）鸿联云平台规则、公告、通知或各平台与您单独签署的协议中已明确说明不适合在鸿联云平台上销售及/或提供的。</span
            ></em
          >
        </p>
        <p>
          <em>【交易秩序保障】</em
          >您应当遵守诚实信用原则，确保您所发布的商品及/或服务信息真实、与您实际所销售的商品及/或提供的服务相符，并在交易过程中切实履行您的交易承诺。
        </p>
        <p>
          您应当维护鸿联云平台市场良性竞争秩序，不得贬低、诋毁竞争对手，不得干扰鸿联云平台上进行的任何交易、活动，不得以任何不正当方式提升或试图提升自身的信用度，不得以任何方式干扰或试图干扰鸿联云平台的正常运作。
        </p>
        <p>
          <em>【促销及推广】</em
          >您有权自行决定商品及/或服务的促销及推广方式，鸿联云亦为您提供了形式丰富的促销推广工具。<em
            ><span
              >您的促销推广行为应当符合国家相关法律法规、鸿联云平台规则及鸿联云经营者的要求。</span
            ></em
          >
        </p>
        <p>
          <em
            >【依法纳税】<span
              >依法纳税是每一个公民、企业应尽的义务，您应对销售额/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。</span
            ></em
          >
        </p>
        <p>
          您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱鸿联云平台正常交易秩序的行为。基于维护鸿联云平台交易秩序及交易安全的需要，<em
            ><span
              >鸿联云发现上述情形时可主动执行关闭相关交易订单等操作。</span
            ></em
          >
        </p>
        <p>
          <em>【评价】</em
          >您有权在鸿联云平台提供的评价系统中对与您达成交易的其他用户商品及/或服务进行评价。您应当理解，您在鸿联云平台的评价信息是公开的，如您不愿意在评价信息中向公众披露您的身份信息，您有权选择通过匿名形式发表评价内容。
        </p>
        <p>
          您的所有评价行为应遵守鸿联云平台规则的相关规定，评价内容应当客观真实，不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索。<em
            ><span
              >鸿联云可按照鸿联云平台规则的相关规定对您实施上述行为所产生的评价信息进行删除或屏蔽。</span
            ></em
          >
        </p>
        <p>4.3交易争议处理</p>
        <p>
          <em>【交易争议处理途径】</em
          >您在鸿联云平台交易过程（购买商品或服务等）中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
        </p>
        <p>（一）与争议相对方自主协商；</p>
        <p>（二）使用鸿联云平台提供的争议调处服务；</p>
        <p>（三）请求消费者协会或者其他依法成立的调解组织调解；</p>
        <p>（四）向有关行政部门投诉；</p>
        <p>（五）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</p>
        <p>（六）向人民法院提起诉讼。</p>
        <p>
          <em>【平台调处服务】</em
          >如您依据鸿联云平台规则使用鸿联云平台的争议调处服务，则表示您认可并愿意履行鸿联云平台的客服或大众评审员（“调处方”）作为独立的第三方根据其所了解到的争议事实并依据鸿联云平台规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在鸿联云平台调处决定作出前，您可选择上述（三）、（四）、（五）、（六）途径（下称“其他争议处理途径”）解决争议以中止鸿联云平台的争议调处服务。
        </p>
        <p>
          <em
            ><span
              >如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。</span
            ></em
          >
        </p>
        <p><em>4.4费用</em></p>
        <p>
          鸿联云为鸿联云平台向您提供的服务付出了大量的成本，除鸿联云平台明示的收费业务外，其它服务都是免费的。如未来鸿联云向您收取合理费用，鸿联云会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。
        </p>
        <p><em>4.6责任限制</em></p>
        <p>
          <em
            >【不可抗力及第三方原因】<span
              >鸿联云依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，鸿联云并不承担相应的违约责任：</span
            ></em
          >
        </p>
        <p>
          <em
            ><span
              >（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</span
            ></em
          >
        </p>
        <p>
          <em
            ><span
              >（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</span
            ></em
          >
        </p>
        <p>
          <em
            ><span
              >（三）在鸿联云已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</span
            ></em
          >
        </p>
        <p>
          <em>【海量信息】</em
          >鸿联云仅向您提供鸿联云平台服务，您了解鸿联云平台上的信息系用户自行发布，且可能存在风险和瑕疵。<em
            ><span
              >鉴于鸿联云平台具备存在海量信息及信息网络环境下信息与服务的非标准的特点，鸿联云无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。</span
            ></em
          >
        </p>
        <p>
          <em>【调处决定】</em
          >您理解并同意，在争议调处服务中，鸿联云平台的客服、大众评审员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，<em
            ><span>除存在故意或重大过失外，调处方对争议调处决定免责。</span></em
          >
        </p>
        <strong>五、 用户信息的保护及授权</strong>
        <p><em>5.1个人信息的保护</em></p>
        <p>
          鸿联云非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用鸿联云提供的服务时，您同意鸿联云按照在鸿联云平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。鸿联云希望通过隐私权政策向您清楚地介绍鸿联云对您个人信息的处理方式，因此鸿联云建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。
        </p>
        <p><em>5.2非个人信息的保证与授权</em></p>
        <p>
          <em>【信息的发布】</em
          >您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，<em
            ><span
              >鸿联云可对您发布的信息依法或依本协议进行删除或屏蔽。</span
            ></em
          >
        </p>
        <p>
          <em
            >【禁止性信息】<span
              >您应当确保您所发布的信息不包含以下内容：</span
            ></em
          >
        </p>
        <p><em>（一）违反国家法律法规禁止性规定的；</em></p>
        <p>
          <em
            >（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</em
          >
        </p>
        <p><em>（三）欺诈、虚假、不准确或存在误导性的；</em></p>
        <p>
          <em>（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</em>
        </p>
        <p>
          <em>（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</em>
        </p>
        <p>
          <em
            >（六）存在可能破坏、篡改、删除、影响鸿联云平台任何系统正常运行或未经授权秘密获取鸿联云平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</em
          >
        </p>
        <p>
          <em
            >（七）其他违背社会公共利益或公共道德或依据相关鸿联云平台协议、规则的规定不适合在鸿联云平台上发布的。</em
          >
        </p>
        <p>
          <em>【授权使用】</em
          >对于您提供、发布及在使用鸿联云平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，<em
            ><span
              >在法律规定的保护期限内您免费授予鸿联云及其关联公司获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。您同意鸿联云及其关联公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</span
            ></em
          >
        </p>
        <strong>六、 用户的违约及处理</strong>
        <p><em>6.1 违约认定</em></p>
        <p>发生如下情形之一的，视为您违约：</p>
        <p>（一）使用鸿联云平台服务时违反有关法律法规规定的；</p>
        <p>（二）违反本协议或鸿联云平台其他规则约定的。</p>
        <p>
          为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，<em
            ><span
              >鸿联云可在鸿联云平台规则中约定违约认定的程序和标准。</span
            ></em
          >
        </p>
        <p><em>6.2 违约处理措施</em></p>
        <p>
          <em>【信息处理】</em>您在鸿联云平台上发布的信息构成违约的，<em
            ><span
              >鸿联云可根据相应规则立即对相应信息进行删除、屏蔽处理或对您的商品及／或服务进行下架、监管。</span
            ></em
          >
        </p>
        <p>
          <em>【行为限制】</em
          >您在鸿联云平台上实施的行为，或虽未在鸿联云平台上实施但对鸿联云平台及其用户产生影响的行为构成违约的，<em
            ><span
              >鸿联云可依据相应规则对您执行账户扣分、限制参加营销活动、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，鸿联云可查封您的账户，终止向您提供服务。</span
            ></em
          >
        </p>
        <p>
          <em
            >【处理结果公示】<span
              >鸿联云可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在鸿联云平台上予以公示。</span
            ></em
          >
        </p>
        <p><em>6.3赔偿责任</em></p>
        <p>
          <em
            ><span
              >如您的行为使鸿联云及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿鸿联云及/或其关联公司的上述全部损失。如您的行为使鸿联云及/或其关联公司遭受第三人主张权利，鸿联云及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</span
            ></em
          >
        </p>
        <p>
          <em
            ><span
              >如因您的行为使得第三人遭受损失或您怠于履行调处决定、鸿联云及/或其关联公司出于社会公共利益保护或消费者权益保护目的，可指示第三方支付公司自您的账户中划扣相应款项进行支付。
              如您的第三方支付余额或保证金不足以支付相应款项的，我方可介入与您协商及时充值，以便第三方划扣相应款项，您如逾期不充值，我方可采取终止合同、关店等措施。</span
            ></em
          >
        </p>
        <p>
          <em
            ><span
              >您同意鸿联云指示第三方支付公司自您的第三方账户中划扣相应款项支付上述赔偿款项。如您第三方支付账户中的款项不足以支付上述赔偿款项的（修改），鸿联云及/或关联公司可直接抵减您在鸿联云及/或其关联公司其它协议项下的权益，并可继续追偿。</span
            ></em
          >
        </p>
        <p><em>6.4特别约定</em></p>
        <p>
          <em>【商业贿赂】</em
          >如您向鸿联云及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。<em
            ><span
              >发生上述情形的，鸿联云可立即终止与您的所有合作并向您收取违约金及/或赔偿金，</span
            >该等金额以鸿联云因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。</em
          >
        </p>
        <p>
          <em
            >【关联处理】<span
              >如您因严重违约导致鸿联云终止本协议时，出于维护平台秩序及保护消费者权益的目的，鸿联云及/或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第八条约定的方式通知您。</span
            ></em
          >
        </p>
        <p>
          <em
            ><span
              >如鸿联云与您签署的其他协议及鸿联云及/或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则鸿联云出于维护平台秩序及保护消费者权益的目的，可在收到指令时中止甚至终止协议，并以本协议第八条约定的方式通知您。</span
            ></em
          >
        </p>
        <strong>七、 协议的变更</strong>
        <p>
          鸿联云可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。
        </p>
        <p>
          如您不同意变更事项，您有权于变更事项确定的生效日前联系鸿联云反馈意见。如反馈意见得以采纳，鸿联云将酌情调整变更事项。
        </p>
        <p>
          <em
            ><span
              >如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用鸿联云平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用鸿联云平台服务，则视为您同意已生效的变更事项</span
            ></em
          >
        </p>
        <strong>八、 通知</strong>
        <p><em>8.1有效联系方式</em></p>
        <p>
          您在注册成为鸿联云平台用户，并接受鸿联云平台服务时，您应该向鸿联云提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
        </p>
        <p>
          您在注册鸿联云平台用户时生成的用于登陆鸿联云平台接收站内信、系统消息和平台IM工具等，也作为您的有效联系方式。
        </p>
        <p>
          <em
            >鸿联云将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</em
          >
        </p>
        <p>
          您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；<em
            >您如果不愿意接收此类信息，您有权通过鸿联云提供的相应的退订功能进行退订。</em
          >
        </p>
        <p><em>8.2 通知的送达</em></p>
        <p>
          鸿联云通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在鸿联云平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送平台信息、系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
        </p>
        <p>
          对于在鸿联云平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件或站内信等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱或站内信账号等联系方式为您在鸿联云平台注册、更新时提供的手机号码、电子邮箱联系方式以及在注册鸿联云用户时生成站内信，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。
        </p>
        <p>
          您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。
        </p>
        <p>
          您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。
        </p>
        <p>
          你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
        </p>
        <strong>九、 协议的终止</strong>
        <p><em>9.1 终止的情形</em></p>
        <p><em>【用户发起的终止】</em>您有权通过以下任一方式终止本协议：</p>
        <p>
          （一）在满足鸿联云网公示的账户注销条件时您通过网站自助服务注销您的账户的；
        </p>
        <p>（二）变更事项生效前您停止使用并明示不愿接受变更事项的；</p>
        <p>（三）您明示不愿继续使用鸿联云平台服务，且符合鸿联云终止条件的。</p>
        <p>
          <em>【鸿联云发起的终止】</em
          >出现以下情况时，鸿联云可以本协议第八条的所列的方式通知您终止本协议：
        </p>
        <p>（一）您违反本协议约定，鸿联云依据违约条款终止本协议的；</p>
        <p>
          （二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，鸿联云依据鸿联云平台规则对您的账户予以查封的；
        </p>
        <p>
          （三）除上述情形外，因您多次违反鸿联云平台规则相关规定且情节严重，鸿联云依据鸿联云平台规则对您的账户予以查封的；
        </p>
        <p>（四）您的账户被鸿联云依据本协议回收的；</p>
        <p>（五）其它应当终止服务的情况。</p>
        <p><em>9.2 协议终止后的处理</em></p>
        <p>
          <em
            >【用户信息披露】<span
              >本协议终止后，除法律有明确规定外，鸿联云无义务向您或您指定的第三方披露您账户中的任何信息。</span
            ></em
          >
        </p>
        <p><em>【鸿联云权利】</em>本协议终止后，鸿联云仍享有下列权利：</p>
        <p>（一）继续保存您留存于鸿联云平台的本协议第五条所列的各类信息；</p>
        <p>
          （二）对于您过往的违约行为，鸿联云仍可依据本协议向您追究违约责任。
        </p>
        <p>
          <em
            >【交易处理】<span
              >本协议终止后，对于您在本协议存续期间产生的交易订单，鸿联云可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</span
            ></em
          >
        </p>
        <strong>十、 法律适用、管辖与其他</strong>
        <p>
          <em
            >【法律适用】<span
              >本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</span
            ></em
          >
        </p>
        <p>
          <em>【管辖】</em
          >您因使用鸿联云平台服务所产生及与鸿联云平台服务有关的争议，由鸿联云与您协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。
        </p>
        <p>
          <em>【可分性】</em
          >本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
        </p>
        <p>
          <em
            >您同意鸿联云经营者在鸿联云平台上发布的<span
              >法律声明及隐私权政策等鸿联云平台规则均为本协议不可分割的一部分，与本协议具有同等法律效力。如您使用鸿联云平台服务，视为您同意上述鸿联云平台规则。</span
            ></em
          >
        </p>
        <strong>十一、最终解释权</strong>
        <p>鸿联云平台对协议以及基于本协议制定的各项规则拥有最终解释权。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.protocol-box {
  padding: 24px 0;
  > div {
    width: 1280px;
    padding: 24px;
    margin: 0 auto;
    background: #fff;
    > p {
      text-align: center;
      font-size: 24px;
      margin-bottom: 50px;
    }
    > div {
      > strong {
        font-size: 20px;
        line-height: 26px;
        display: block;
        margin-bottom: 24px;
      }
      > em {
        font-size: 20px;
        line-height: 26px;
        display: block;
        margin-bottom: 24px;
        font-style: initial;
        font-weight: bold;
      }
      > p {
        font-size: 16px;
        line-height: 24px;
        text-indent: 2em;
        margin-bottom: 24px;
        > span {
          text-decoration: underline;
        }
        > em {
          font-size: 16px;
          line-height: 24px;
          font-style: initial;
          font-weight: bold;
          > span {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
